import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import {
    onboardingBookingPath,
    onboardingBookingSkipPath,
    onboardingCongratulationsPath,
    onboardingLevelPath,
    onboardingLevelPickPath,
    onboardingLevelPlacedPath,
    onboardingLevelSubPath,
    onboardingPath,
    onboardingSurveyPath,
    onboardingTimezonePath,
    onboardingWelcomePath,
    optionalArg,
} from '@lingoda/router';
import Booking from '../pages/Booking';
const Main = lazy(() => import('../pages/Main'));
const Survey = lazy(() => import('../pages/Survey'));
const Welcome = lazy(() => import('../pages/Welcome'));
const Timezone = lazy(() => import('../pages/Timezone'));
const Level = lazy(() => import('../pages/Level'));
const SubLevel = lazy(() => import('../pages/Level/SubLevel'));
const PickLevel = lazy(() => import('../pages/Level/PickLevel'));
const PlacedLevel = lazy(() => import('../pages/Level/PlacedLevel'));
const BookingSkip = lazy(() => import('../pages/Booking/Skip'));
const Congratulations = lazy(() => import('../pages/Congratulations'));

export const onboardingRoutes: RouteConfigExtended[] = [
    {
        component: Main,
        path: onboardingPath.raw,
        label: 'Onboarding',
        config: {
            hideFooter: true,
            hideMobileBottomNavbar: true,
            hideHeader: true,
            tab: undefined,
        },
        routes: [
            {
                component: Survey,
                label: 'MarketingQuestionnaire',
                path: onboardingSurveyPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                    tab: undefined,
                },
            },
            {
                component: Welcome,
                label: 'WelcomeToLingoda',
                path: onboardingWelcomePath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: Timezone,
                label: 'ConfirmTime',
                path: onboardingTimezonePath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: Level,
                label: 'SelectLevel',
                path: onboardingLevelPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
                routes: [
                    {
                        component: PickLevel,
                        path: onboardingLevelPickPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                    {
                        component: PlacedLevel,
                        path: onboardingLevelPlacedPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                    {
                        component: SubLevel,
                        label: 'SelectSublevel',
                        path: optionalArg(onboardingLevelSubPath.raw, 'sublevel'),
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                ],
            },
            {
                exact: true,
                component: Booking,
                label: 'FirstClassTime',
                path: onboardingBookingPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: BookingSkip,
                label: 'SkipOnboardingBooking',
                path: onboardingBookingSkipPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: Congratulations,
                label: 'Congratulations',
                path: onboardingCongratulationsPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
        ],
    },
];
