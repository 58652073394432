import { useMemo } from 'react';
import { useCanBookOnboardingClasses } from '@lingoda/booking';
import {
    onboardingBookingPath,
    onboardingCongratulationsPath,
    onboardingLevelPath,
    onboardingTimezonePath,
    useLocation,
} from '@lingoda/router';
import { useIsLearningTeams } from '@lingoda/learning-teams';
import { Step, Stepper } from './Stepper';

const stepsWithoutBooking = [
    onboardingTimezonePath(),
    onboardingLevelPath(),
    onboardingCongratulationsPath(),
];

const stepsWithBooking = [
    onboardingTimezonePath(),
    onboardingLevelPath(),
    onboardingBookingPath(),
    onboardingCongratulationsPath(),
];

const stepsTeams = [onboardingTimezonePath(), onboardingCongratulationsPath()];

export const Progress = () => {
    const canBookClasses = useCanBookOnboardingClasses();
    const isLearningTeams = useIsLearningTeams();
    const steps = useMemo(
        () =>
            isLearningTeams ? stepsTeams : canBookClasses ? stepsWithBooking : stepsWithoutBooking,
        [canBookClasses, isLearningTeams],
    );

    const { pathname } = useLocation();
    const activeStep = useMemo(
        () => steps.findIndex((step) => pathname.startsWith(step.toString())) + 1,
        [pathname, steps],
    );

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((_, index) => (
                <Step key={index} />
            ))}
        </Stepper>
    );
};
