import { LingodaLogo } from '@lingoda/logotype';
import { trans } from '@lingoda/i18n';
import { AppBar, Box, Button, IconButton } from '@lingoda/ui';
import { ArrowBack } from '@lingoda/ui/icons';
import Toolbar from './Toolbar';

interface Props {
    onBack?: () => void;
    onSkip?: () => void;
}

export const Header = ({ onSkip, onBack }: Props) => (
    <AppBar position="static" color="inherit">
        <Toolbar>
            {onBack ? (
                <IconButton onClick={onBack}>
                    <ArrowBack />
                </IconButton>
            ) : (
                <Box ml={2}>
                    <LingodaLogo isIcon width={24} height={24} />
                </Box>
            )}

            {onSkip && (
                <Button variant="text" onClick={onSkip} sx={{ mr: 2 }}>
                    {trans('skip', {}, 'student-onboarding')}
                </Button>
            )}
        </Toolbar>
    </AppBar>
);
