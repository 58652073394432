// @TODO: move this file to the rootDir
import { lazy } from 'react';
import { storeRoutes } from '@lingoda/store';
import { subscriptionRoutes } from '@lingoda/membership';
import type { RouteConfigExtended } from '@lingoda/router';
import { leadConversionFlowRoutes } from '@lingoda/lead-conversion-flow';
import { getStartedRoutes, onboardingRoutes } from '@lingoda/student-onboarding';
import { freeTrialPurchaseRoutes } from '@lingoda/free-trial-purchase';
import { emailVerificationRoutes } from '@lingoda/email-verification';
import { packagePurchaseRoutes } from '@lingoda/package-purchase';
import { postCheckoutRoutes } from '@lingoda/student-post-checkout';
import commonRoutes from './common';
import publicRoutes from './public';
import studentRoutes from './student';

const Main = lazy(() => import('../components/pages/Main'));

const routes: RouteConfigExtended[] = [
    {
        component: Main,
        label: 'Main',
        routes: [
            ...publicRoutes,
            ...emailVerificationRoutes,
            ...studentRoutes,
            ...leadConversionFlowRoutes,
            ...onboardingRoutes,
            ...postCheckoutRoutes,
            ...getStartedRoutes,
            ...storeRoutes,
            ...subscriptionRoutes,
            ...freeTrialPurchaseRoutes,
            ...packagePurchaseRoutes,
            ...commonRoutes,
        ],
        config: undefined,
    },
];

export default routes;
