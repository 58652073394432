import type { PaymentMethodsResponse } from '@lingoda/api';
import { handleActionsImmutably } from '@lingoda/core';
import { deletePaymentContract, fetchPaymentMethods } from '../actions';
import type { PaymentMethod } from '../models';

export type State = Readonly<GenericObject<PaymentMethod>>;

type FetchPaymentMethodsAction = ReturnType<typeof fetchPaymentMethods.success>;

// We have to remove SOFORT for now, as a quick fix we're filtering it out
// Will be fixed appropriately under the ticket https://lingoda.atlassian.net/browse/LW-30270
const IGNORED_PAYMENT_METHOD_ID = 'sofort';

export default handleActionsImmutably<State, FetchPaymentMethodsAction['payload']>(
    {
        [`${fetchPaymentMethods.success}`]: (_state, { payload }: FetchPaymentMethodsAction) =>
            (payload.result as PaymentMethodsResponse).data.reduce(
                (newState: GenericObject<PaymentMethod>, method) => {
                    if (method.id === IGNORED_PAYMENT_METHOD_ID) {
                        return newState;
                    }

                    newState[method.id] = method;

                    return newState;
                },
                {},
            ),
        [`${deletePaymentContract.success}`]: (
            state,
            { payload }: ReturnType<typeof deletePaymentContract.success>,
        ) => {
            delete state[payload.payload];

            return state;
        },
    },
    {},
);
