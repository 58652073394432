import MuiTableCell, { type TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import { styled } from '../../theme';

interface TableCellProps extends MuiTableCellProps {
    edge?: 'start' | 'end' | false;
}

export const TableCell = styled(MuiTableCell, {
    overridesResolver: (prop) => prop !== 'edge',
    shouldForwardProp: (prop) => prop !== 'edge',
})<TableCellProps>(({ edge }) => ({
    ...(edge === 'start' && { paddingLeft: 0 }),
    ...(edge === 'end' && { paddingRight: 0 }),
}));

export { tableCellClasses, type TableCellProps } from '@mui/material/TableCell';
