import { useCallback } from 'react';
import { usePersistentState } from './usePersistentState';

export const useWidgetPersistentState = (cacheKey: string) => {
    const [isClosed, setIsClosed] = usePersistentState<boolean>(cacheKey, false);

    const close = useCallback(() => setIsClosed(true), [setIsClosed]);

    return { isVisible: !isClosed, isHidden: !!isClosed, close };
};
