import { useMemo } from 'react';
import { getSessionValue, setSessionValue } from '@lingoda/utils';
import type { DialogBaseProps } from '@lingoda/dialogs';
import { type CalendarType, useCalendarListQuery } from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import { MISSED_CLASS_CALENDAR_DIALOG_WAS_SHOWN_SESSION_KEY } from '../const';
import { shouldShowConnectCalendarDialogForMissedClass } from '../logic/shouldShowConnectCalendarDialogForMissedClass';
import { useConnectCalendar } from './useConnectCalendar';

export const useMissedClassConnectCalendarDialog = ({
    hideDialog,
}: Pick<DialogBaseProps, 'hideDialog'>) => {
    const { data, loading } = useCalendarListQuery({
        onError: showApolloErrorToast,
    });

    const { shouldShowConnectCalendarDialog, options } = useMemo(() => {
        const calendars = data?.calendarList || [];

        const wasDialogShownInCurrentSession =
            getSessionValue(MISSED_CLASS_CALENDAR_DIALOG_WAS_SHOWN_SESSION_KEY) === true;

        return {
            shouldShowConnectCalendarDialog: shouldShowConnectCalendarDialogForMissedClass(
                calendars.map(({ status }) => status),
                wasDialogShownInCurrentSession,
            ),
            options: calendars.map(({ type }) => ({ type })),
        };
    }, [data?.calendarList]);

    const { connectCalendar, type, loading: connectLoading } = useConnectCalendar();

    const handledDismiss = () => {
        if (connectLoading) {
            return;
        }

        setSessionValue(MISSED_CLASS_CALENDAR_DIALOG_WAS_SHOWN_SESSION_KEY, true);
        hideDialog();
    };

    const handleOptionSelect = async (type: CalendarType) => {
        await connectCalendar(type);
        handledDismiss();
    };

    return {
        loading,
        connectLoading,
        type,
        handleOptionSelect,
        handledDismiss,
        shouldShowConnectCalendarDialog,
        options,
    };
};
