import { trans } from '@lingoda/i18n';
import { Box, Button, type ButtonProps, Grid } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { Container } from '../Container';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Progress } from './components/Progress';
import type { PropsWithChildren } from 'react';

interface Props {
    onBack?: () => void;
    onSkip?: () => void;
    fullWidth?: boolean;
    confirmProps?: Omit<ButtonProps, 'color' | 'size'>;
}

export const OnboardingFrame = ({
    onSkip,
    onBack,
    children,
    fullWidth,
    confirmProps,
}: PropsWithChildren<Props>) => (
    <GridContainer container direction="column" wrap="nowrap">
        <Grid item>
            <Header onBack={onBack} onSkip={onSkip} />
            <Progress />
        </Grid>
        <Grid item flex={1}>
            <Box p={{ xs: 2, md: 6 }} height="100%">
                {fullWidth ? (
                    children
                ) : (
                    <Container>
                        <>{children}</>
                    </Container>
                )}
            </Box>
        </Grid>
        <Grid item>
            {confirmProps && (
                <Footer>
                    <Container>
                        {confirmProps.variant === 'outlined' ? (
                            <Button fullWidth color="primary" variant="outlined" {...confirmProps}>
                                {confirmProps.children ||
                                    trans('continue', {}, 'student-onboarding')}
                            </Button>
                        ) : (
                            <Button color="primary" variant="contained" fullWidth {...confirmProps}>
                                {confirmProps.children ||
                                    trans('continue', {}, 'student-onboarding')}
                            </Button>
                        )}
                    </Container>
                </Footer>
            )}
        </Grid>
    </GridContainer>
);

const GridContainer = styled(Grid)(({ theme }) => ({
    flex: 1,
    minHeight: '100%',
    background: `linear-gradient(287.84deg,
                rgba(153, 0, 204, 0.05) 0%,
                rgba(142, 5, 208, 0.05) 0.01%,
                rgba(21, 59, 255, 0.05) 17.81%,
                rgba(255, 255, 255, 0.05) 37.89%,
                rgba(255, 255, 255, 0.05) 60.8%,
                rgba(27, 73, 221, 0.05) 77.73%,
                rgba(163, 0, 219, 0.05) 100%)`,
    [theme.breakpoints.down('sm')]: {
        background: theme.palette.common.white,
    },
}));
