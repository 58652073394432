import { addDays, createDate, diffInUnits } from '@lingoda/dates';
import { useAppointmentsCountQuery } from '@lingoda/graphql';
import { useSelector, useWidgetPersistentState } from '@lingoda/hooks';
import { canRolloverCurrentSubscriptionSelector, currentSubscriptionSelector } from '../expanded';

export const useShowRolloverTrialBanner = () => {
    const { isVisible: isBannerVisible, close: closeBanner } =
        useWidgetPersistentState('SKIP_TRIAL_BANNER');
    const subscription = useSelector(currentSubscriptionSelector);
    const canRolloverCurrentSubscription = useSelector(canRolloverCurrentSubscriptionSelector);

    const { data } = useAppointmentsCountQuery({ fetchPolicy: 'cache-and-network' });
    const { upcomingAppointmentsCount = 0, pastAppointmentsCount = 0 } = data || {};

    const showBanner =
        isBannerVisible &&
        canRolloverCurrentSubscription &&
        (pastAppointmentsCount > 0 ||
            (upcomingAppointmentsCount > 0 &&
                diffInUnits('days', createDate(), addDays(subscription?.lastChargedAt as Date, 5)) >
                    0));

    return [showBanner, closeBanner] as const;
};
