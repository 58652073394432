import { Step as MuiStep, colorsPalette, styled } from '@lingoda/ui';

export const Step = styled(MuiStep)({
    height: 2,
    padding: 0,
    borderRightWidth: 4,
    borderRightStyle: 'solid',
    borderRightColor: colorsPalette.whiteSolid[100],
    background: colorsPalette.blackSolid[8],
    '&.Mui-completed': {
        background: 'transparent',
    },
});
