import { useCalendarListQuery } from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch } from '@lingoda/hooks';
import { useSelectWasCalendarWidgetDismissed } from '../selectors';
import { wasAnyCalendarEverConnected } from '../logic/wasAnyCalendarEverConnected';

export const useShowCalendarConnectionDialog = () => {
    const dispatch = useDispatch();
    const { data, loading } = useCalendarListQuery({
        onError: showApolloErrorToast,
    });
    const didUserDismissDialog = useSelectWasCalendarWidgetDismissed();
    const hasCalendarConnectedAlready = wasAnyCalendarEverConnected(
        data?.calendarList.map(({ status }) => status) || [],
    );

    return {
        shouldShowCalendarConnectDialog:
            !hasCalendarConnectedAlready && !didUserDismissDialog && !loading,
        showCalendarConnectDialog: () => {
            dispatch(showDialog(DialogComponent.ConnectCalendarDialog, { isOpen: true }));
        },
    };
};
